import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

const AboutClientsSlider = () => {
  const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    vertical: true,
    infinite: true,
    swipe: false,
    touchMove: false,
    autoplay: true,
    verticalSwiping: false,
    speed: 1000,
    autoplaySpeed: 0,
  };

  return (
    <>
      <style jsx global>{`
        /* Smooth scrolling animation for the slider */
        @keyframes smoothScroll {
          0% {
            transform: translateY(0);
          }
          100% {
            transform: translateY(-100%);
          }
        }

        .about-clients-desk .slick-track {
          display: flex;
          flex-direction: column;
          gap: 30px;
          animation: smoothScroll 15s linear infinite; /* 15 seconds for one loop */
        }

        .about-clients-desk .slick-list {
          height: 380px;
        }

        /* Duplicate slides to ensure infinite loop effect */
        .about-clients-desk .slick-slide {
          flex-shrink: 0;
          height: 92px; /* Match your slide height */
        }
      `}</style>
      <Slider {...settings} className="about-clients-desk w-full !h-[380px] !overflow-hidden">
        <div className="!flex !flex-row !justify-between !w-full  !items-center !h-[92px]  ">
          <img
            src="/images/video-production/logos/civilAvtation.png"
            alt="client"
            className="grayscale opacity-50 w-[196x] h-[50px]  object-contain"
          />
          <img src="/images/video-production/logos/bayshore.png" alt="client" className="grayscale opacity-50 w-[197x] h-[50px] object-contain" />
          <img src="/images/video-production/logos/iata.png" alt="client" className="grayscale opacity-50 w-[122x] h-[78px] object-contain" />
        </div>

        <div className="!flex !flex-row !justify-between !items-center !h-[92px]  ">
          <img src="/images/video-production/logos/perkinElmer.png" alt="client" className="grayscale opacity-50 w-[149x] h-[64px] object-contain" />
          <img src="/images/video-production/logos/dnv.png" alt="client" className="grayscale opacity-50 w-[149x] h-[64px] object-contain" />
          <img src="/images/video-production/logos/kappitaan.png" alt="client" className="grayscale opacity-50 w-[201x] h-[49px] object-contain" />
        </div>

        <div className="!flex !flex-row !justify-between !items-center !h-[92px]  ">
          <img src="/images/video-production/logos/girp.png" alt="client" className="grayscale opacity-50 w-[106x] h-[44px] object-contain" />
          <img src="/images/video-production/logos/matador.png" alt="client" className="grayscale opacity-50 w-[180x] h-[43px] object-contain" />
          <img src="/images/video-production/logos/lanehelth.png" alt="client" className="grayscale opacity-50 w-[216x] h-[46px] object-contain" />
        </div>

        <div className="!flex !flex-row !justify-between !items-center !h-[92px]  ">
          <img src="/images/video-production/logos/hsbc.png" alt="client" className="grayscale opacity-50 w-[191x] h-[51px] object-contain" />
          <img src="/images/video-production/logos/dcvmn.png" alt="client" className="grayscale opacity-50 w-[190x] h-[51px] object-contain" />
          <img src="/images/video-production/logos/atkins.png" alt="client" className="grayscale opacity-50 w-[101x] h-[92px] object-contain" />
        </div>
        <div className="!flex !flex-row !justify-between !items-center !h-[92px]  ">
          <img src="/images/video-production/logos/saferoom.png" alt="client" className="grayscale opacity-50 w-[133x] h-[71px] object-contain" />
          <img src="/images/video-production/logos/vshred.png" alt="client" className="grayscale opacity-50 w-[176x] h-[36px] object-contain" />
          <img src="/images/video-production/logos/pumpables.png" alt="client" className="grayscale opacity-50 w-[166x] h-[32px] object-contain" />
        </div>
        <div className="!flex !flex-row !justify-between !items-center !h-[92px]  ">
          <img src="/images/video-production/logos/tapncare.png" alt="client" className="grayscale opacity-50 w-[200x] h-[50px] object-contain" />
          <img src="/images/video-production/logos/cozii.png" alt="client" className="grayscale opacity-50 w-[116x] h-[81px] object-contain" />
          <img src="/images/video-production/logos/spacewell.png" alt="client" className="grayscale opacity-50 w-[146x] h-[76px] object-contain" />
        </div>
        <div className="!flex !flex-row !justify-between !items-center !h-[92px]  ">
          <img src="/images/video-production/logos/ardshin.png" alt="client" className="grayscale opacity-50 w-[120x] h-[79px] object-contain" />
          <img
            src="/images/video-production/logos/mobilityStream.png"
            alt="client"
            className="grayscale opacity-50 w-[220x] h-[39px] object-contain"
          />
          <img src="/images/video-production/logos/uniskai.png" alt="client" className="grayscale opacity-50 w-[124x] h-[77px] object-contain" />
        </div>
        <div className="!flex !flex-row !justify-between !items-center !h-[92px]  ">
          <img src="/images/video-production/logos/dropit.png" alt="client" className="grayscale opacity-50 w-[140x] h-[25px] object-contain" />
          <img src="/images/video-production/logos/hopshop.png" alt="client" className="grayscale opacity-50 w-[157x] h-[26px] object-contain" />
          <img src="/images/video-production/logos/dt.png" alt="client" className="grayscale opacity-50 w-[140x] h-[42px] object-contain" />
        </div>
        <div className="!flex !flex-row !justify-between !items-center !h-[92px]  ">
          <img src="/images/video-production/logos/chord.png" alt="client" className="grayscale opacity-50 w-[140x] h-[47px] object-contain" />
          <img src="/images/video-production/logos/projectAgora.png" alt="client" className="grayscale opacity-50 w-[150x] h-[26px] object-contain" />
          <img src="/images/video-production/logos/scoutely.png" alt="client" className="grayscale opacity-50 w-[148x] h-[37px] object-contain" />
        </div>
      </Slider>
    </>
  );
};

export default AboutClientsSlider;

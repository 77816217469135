import Link from 'next/link';

const CertifiedByItem = ({ item }) => {
  return (
    <div className="md:w-[190px] w-[160px] flex flex-col gap-[16px]">
      {!!item.url ? (
        <a href={item.url} className="cursor-pointer min-w-max">
          <img src={item.image} alt={item.name} className="w-fit" />
        </a>
      ) : (
        <img src={item.image} alt={item.name} className="w-fit" />
      )}
      <p className="text-[12px] font-light leading-[15px]">{item.description}</p>
    </div>
  );
};

export default CertifiedByItem;

import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import InsideProdigiMobileItem from './InsideProdigiMobileItem';
import st from './index.module.css';

const tabs = [
  {
    label: 'ourCulture',
    value: 'Our Culture',
    content: {
      title: 'Our Culture',
      description:
        'Creativity, teamwork, and innovation are the backbone of our company at Weprodigi. Our staff is dedicated to providing custom solutions while at the same time building a cooperative and welcoming culture. By focusing on teamwork, honesty and integrity, and dedication to quality, we develop strong relationships and maintain and deliver great outcomes every time.',
      linkUrl: '/team',
      linkText: 'Look Inside Prodigi',
      image: '/images/home/about/ourCulture-mob.png',
    },
  },
  {
    label: 'ourAwards',
    value: 'Our Awards',
    content: {
      title: 'Our Awards',
      description:
        'It is an honor to be recognized as a Yandex regional partner for multiple years in a row, proving our professionalism, dedication to quality, and influence in the region. Additionally, our official verification by Meta highlights our expertise and commitment to excellence in the industry.',
      linkUrl: '/about-us',
      linkText: 'Our Awards',
      image: '/images/home/about/ourAwards.png',
    },
  },
  {
    label: 'communityImpact',
    value: 'Community Impact',
    content: {
      title: 'Community Impact',
      description:
        'At Weprodigi, we are focused on making an impact. Whether it`s raising awareness for breast cancer or creating an impact on society, we look to do our part in making a difference. Through our commitment to spending at least 1% of our resources towards health initiatives, we look to encourage people to take necessary action and to strengthen communities.',
      image: '/images/home/about/communityImpact.png',
    },
  },
];
const InsideProdigiMobile = () => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [activeTab, setActiveTab] = useState('ourCulture');

  let sliderRef1 = useRef(null);
  let sliderRef2 = useRef(null);

  useEffect(() => {
    setNav1(sliderRef1);
    setNav2(sliderRef2);
  }, []);

  const handleSlideChange = currentIndex => {
    setActiveTab(tabs[currentIndex].label);
  };

  return (
    <>
      <style>
        {`
          .inside-slider .slick-list {
            overflow: hidden;  
          }
          .inside-slider .slick-track {
            display: flex;
            align-items: center;  
          }
          .inside-slider .slick-slide {
            outline: none;
            padding-bottom: 16px;
          }
          .inside-slider .slick-slide div {
            outline: none;
          }
         
         
        `}
      </style>
      <div className="bg-white py-[48px] p-[16px]">
        <div className="flex flex-col gap-[48px]">
          <h2 className="flex ml-[12px] font-[600] text-[32px] md:text-[34px] leading-[39px] md:leading-[42px] text-black">Inside Prodigi</h2>

          <Slider
            asNavFor={nav1}
            ref={slider => (sliderRef2 = slider)}
            slidesToShow={1}
            arrows={false}
            dots={false}
            variableWidth={true}
            swipeToSlide={true}
            focusOnSelect={true}
            beforeChange={(_, next) => handleSlideChange(next)}
          >
            {tabs.map(tab => (
              <div key={tab.label} className="px-[12px]">
                <button
                  className={`${st.tab_button} ${activeTab === tab.label ? `${st.active_tab}` : `${st.hover_tab}`}`}
                  onClick={() => {
                    setActiveTab(tab.label);
                    sliderRef1.slickGoTo(tabs.findIndex(t => t.label === tab.label));
                  }}
                >
                  {tab.value}
                </button>
              </div>
            ))}
          </Slider>

          <Slider
            asNavFor={nav2}
            slidesToShow={1}
            variableWidth={true}
            arrows={false}
            dots={false}
            ref={slider => (sliderRef1 = slider)}
            beforeChange={(_, next) => handleSlideChange(next)}
            className="inside-slider w-full h-full"
          >
            {tabs.map(tab => (
              <div key={tab.label} className="px-[12px] max-w-max">
                <InsideProdigiMobileItem item={tab.content} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default InsideProdigiMobile;

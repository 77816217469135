import React from 'react';

import PostsSlider from './PostsSlider';

const BlogPosts = () => {
  return (
    <div className="bg-[#F5F5F5] py-[48px]">
      <div className="container">
        <div className="flex flex-col w-full gap-[48px]">
          <h2 className="font-[600] md:text-center text-[32px] md:text-[34px] leading-[39px] md:leading-[44px] text-black">From the Prodigi Blog</h2>
          <PostsSlider />
        </div>
      </div>
    </div>
  );
};

export default BlogPosts;

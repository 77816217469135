import classNames from 'classnames';
import React, { useState } from 'react';

const CasesCard = props => {
  const { cardClass, logoUrl, logoClass, imageUrl, url, imageClass, increaseNum, increaseText, avgNum, avgText, monthNum, monthText } = props;

  return (
    <a href={url}>
      <div
        className={classNames(
          'w-[300px] md:w-[350px] md:h-[305px] h-[400px] rounded-[10px] relative bg-white flex flex-col items-center  gap-[24px]',
          cardClass
        )}
      >
        <img src={logoUrl} className={logoClass} alt="logo" />
        <div className="flex flex-col justify-center items-center gap-[8px] md:pb-[76px] pb-[32px] text-center">
          <p className="md:text-[18px] text-[16px] font-[400] leading-[22px]">
            <span className="font-[700]">{increaseNum}</span> {increaseText}
          </p>
          <p className="md:text-[18px] text-[16px] font-[400] leading-[22px]">
            <span className="font-[700]">{avgNum}</span> {avgText}
          </p>
          <p className="md:text-[18px] text-[16px] font-[400] leading-[22px]">
            <span className="font-[700]">{monthNum}</span> {monthText}
          </p>
        </div>
        <img src={imageUrl} className={classNames('h-[160px] md:h-[190px] absolute md:right-0', imageClass)} alt="case" />
      </div>
    </a>
  );
};

export default CasesCard;

import React, { useState, useEffect, useRef } from 'react';

import Slider from 'react-slick';

import { getAllArticles } from '../../../../services/blog';
import ArticalSkeleton from './ArticalSkeleton';
import { ArrowButton } from '../../../ui';

function SampleNextArrow(props) {
  const { onClick } = props;

  return (
    <ArrowButton
      className="shadow-[0px_4px_7.1px_0px_rgba(0,0,0,0.17)] -right-[41px] top-[50%] absolute translate-y-[-50%]"
      shape="circle"
      direction="right"
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;

  return (
    <ArrowButton
      className="shadow-[0px_4px_7.1px_0px_rgba(0,0,0,0.17)] -left-[40px] top-[50%] absolute translate-y-[-50%]"
      shape="circle"
      direction="left"
      onClick={onClick}
    />
  );
}

const PostsSlider = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(false);

  const slider = useRef();

  const skeletons = Array.from({ length: 4 }, (_, index) => <ArticalSkeleton key={index} />);

  useEffect(() => {
    setLoading(true);

    getAllArticles().then(data => {
      setArticles(data.posts);
      setLoading(false);
    });
  }, []);

  const settings = {
    infinite: true,
    speed: 500,
    swipe: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    dots: false,
    waitForAnimate: false,
    responsive: [
      {
        breakpoint: 1215,
        settings: {
          variableWidth: true,
          slidesToShow: 1,
          arrows: false,
          dots: true,
          dotsClass: 'custom-dots',
          centerPadding: '15px',
        },
      },
    ],
  };

  return loading ? (
    <>{skeletons}</>
  ) : (
    <>
      <style>
        {`
        .home-blog-slider .slick-list {
          overflow: hidden;  
        }
        .home-blog-slider .slick-track {
          display: flex;
          align-items: center;  
        }
        .home-blog-slider .slick-slide {
          outline: none;
        }
        .home-blog-slider .slick-slide div {
          outline: none;
        }
        .home-blog-slider .custom-dots {
          display: flex !important;
          justify-content: center;
        }
        .home-blog-slider .custom-dots li {
          position: relative;
          margin: 0 3px;
          margin-top: 20px;
          width: 50px;
          height: 2px;
          background-color: #C4C4C4;
          overflow: hidden;
        }
        .home-blog-slider .custom-dots li.slick-active {
          background-color: #D9A8E5;
        }
        .home-blog-slider .custom-dots li button {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          opacity: 0;
        }  
      `}
      </style>
      <Slider ref={slider} {...settings} className="home-blog-slider w-full h-full relative">
        {articles.map((item, ind) => (
          <a key={'item_' + ind} href={'/blog/' + item.slug} className="px-[15px] pr-[15px]">
            <div className="w-[255px] md:w-[340px] md:min-h-max h-[268px] flex flex-col items-start justify-start gap-[20px]">
              <div className="w-[255px] md:w-[340px] md:h-[191px] h-[170px] overflow-hidden">
                <img className="object-cover w-full h-full" src={process.env.NEXT_PUBLIC_IMAGE_PATH + item['main_image']} alt={item.post_title} />
              </div>
              <p className="font-[500] text-[16px]  leading-[21px] ">{item.post_title}</p>
            </div>
          </a>
        ))}
      </Slider>
    </>
  );
};

export default PostsSlider;

import Slider from 'react-slick';
import st from './index.module.css';
import classNames from 'classnames';
import ArrowLink from '../../../ui/ArrowLink';
import data from './data.json';

import TrustedByItem from './TrustedByItem';

const Testimonials = () => {
  const settings = {
    infinite: true,
    speed: 600,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    swipe: false,
    waitForAnimate: false,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          arrows: false,
          swipe: true,
          variableWidth: true,
        },
      },
    ],
  };

  return (
    <div className="flex flex-col md:gap-[24px] relative">
      <div className={classNames('hidden md:block absolute', st.testimonials)}>TESTIMONIALS</div>

      <div className="hidden md:grid md:grid-cols-2 gap-[28px]">
        {data.map((item, index) => (
          <TrustedByItem key={index} {...item} />
        ))}
      </div>

      <div className="block md:hidden relative">
        <Slider {...settings} className="process-slider relative z-10">
          {data.map((item, index) => (
            <div key={index} className="pr-[30px] pb-[24px]">
              <TrustedByItem {...item} />
            </div>
          ))}
        </Slider>
      </div>
      <div className="flex justify-end w-full">
        <ArrowLink link="https://clutch.co/profile/weprodigi-agency#summary" text="See our testimonials" />
      </div>
    </div>
  );
};

export default Testimonials;

import st from './index.module.css';
import ArrowLink from '../../../ui/ArrowLink';
import { useEffect, useRef } from 'react';

const InsideProdigiItem = ({ item, className }) => {
  const cardRef = useRef(null);

  useEffect(() => {
    if (cardRef.current) {
      cardRef.current.classList.remove('home_card');
      void cardRef.current.offsetWidth;
      cardRef.current.classList.add('home_card');
    }
  }, [item]);

  return (
    <div ref={cardRef} className={className}>
      <div className="flex flex-row gap-[30px] items-center min-h-[338px]">
        <div className="flex flex-col justify-between max-w-[570px] min-h-[284px]">
          <div className="flex flex-col justify-center gap-[32px]">
            <h3 className="text-[24px] font-semibold leading-[29px]">{item.title}</h3>
            <p className="text-[16px] font-normal leading-[24px]">{item.description}</p>
          </div>

          {item.linkText && <ArrowLink link={item.linkUrl} text={item.linkText} />}
        </div>

        <img src={item.image} alt={item.title} className="w-fit" />
      </div>
    </div>
  );
};

export default InsideProdigiItem;

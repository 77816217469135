import React from 'react';
import data from './data.json';
import CertifiedByItem from './CertifiedByItem';

const CertifiedBy = () => {
  return (
    <div className="bg-white py-[48px]">
      <div className="container">
        <div className="flex flex-col gap-[48px]">
          <h2 className="md:text-center text-left md:text-[34px] text-[32px] font-semibold md:leading-[72px] leading-[39px]">Certified by</h2>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-[29px]">
            {data.map((item, index) => (
              <CertifiedByItem key={index} item={item} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CertifiedBy;

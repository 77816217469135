import React, { useState, useEffect } from 'react';
import InsideProdigiItem from './InsideProdigiItem';
import st from './index.module.css';

const tabs = [
  {
    label: 'ourCulture',
    value: 'Our Culture',
    content: {
      title: 'Our Culture',
      description:
        'Creativity, teamwork, and innovation are the backbone of our company at Weprodigi. Our staff is dedicated to providing custom solutions while at the same time building a cooperative and welcoming culture. By focusing on teamwork, honesty and integrity, and dedication to quality, we develop strong relationships and maintain and deliver great outcomes every time.',
      linkUrl: '/team',
      linkText: 'Look Inside Prodigi',
      image: '/images/home/about/ourCulture.png',
    },
  },
  {
    label: 'ourAwards',
    value: 'Our Awards',
    content: {
      title: 'Our Awards',
      description:
        'It is an honor to be recognized as a Yandex regional partner for multiple years in a row, proving our professionalism, dedication to quality, and influence in the region. Additionally, our official verification by Meta highlights our expertise and commitment to excellence in the industry.',
      linkUrl: '/about-us',
      linkText: 'Our Awards',
      image: '/images/home/about/ourAwards.png',
    },
  },
  {
    label: 'communityImpact',
    value: 'Community Impact',
    content: {
      title: 'Community Impact',
      description:
        'At Weprodigi, we are focused on making an impact. Whether it`s raising awareness for breast cancer or creating an impact on society, we look to do our part in making a difference. Through our commitment to spending at least 1% of our resources towards health initiatives, we look to encourage people to take necessary action and to strengthen communities.',
      image: '/images/home/about/communityImpact.png',
    },
  },
];

const InsideProdigi = () => {
  const [activeTab, setActiveTab] = useState('ourCulture');

  const content = tabs.find(tab => tab.label === activeTab)?.content;

  const [animationKey, setAnimationKey] = useState(0);

  useEffect(() => {
    setAnimationKey(prevKey => prevKey + 1);
  }, [activeTab]);

  return (
    <div className="bg-white  py-[48px]" id="inside-prodigi">
      <div className="container">
        <div className="flex flex-col gap-[48px] ">
          <h2 className="flex md:justify-center md:items-center font-[600] text-[32px] md:text-[34px] leading-[39px] md:leading-[42px] text-black">
            Inside Prodigi
          </h2>
          <div className="flex w-full flex-col gap-[48px] items-center">
            <div className="flex-row gap-[24px] flex w-full items-center justify-center">
              {tabs.map(tab => (
                <button
                  key={tab.label}
                  className={`${st.tab_button} ${activeTab === tab.label ? `${st.active_tab}` : `${st.hover_tab}`}`}
                  onClick={() => setActiveTab(tab.label)}
                >
                  {tab.value}
                </button>
              ))}
            </div>

            <InsideProdigiItem ket={animationKey} item={content} className="home_card" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsideProdigi;

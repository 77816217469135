import { useEffect, useRef } from 'react';

const useScrollObserver = (ref, onScrollIntoView, onScrollOutOfView = () => {}) => {
  const observer = useRef(null);

  useEffect(() => {
    const element = ref.current;

    if (!(element instanceof Element)) {
      console.error('Invalid element provided to observe');
      return;
    }

    const options = {
      root: null,
      rootMargin: '0px',
      threshold: [0, 0.5, 1],
    };

    observer.current = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        onScrollIntoView();
      } else {
        onScrollOutOfView();
      }
    }, options);

    observer.current.observe(element);

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [ref, onScrollIntoView, onScrollOutOfView]);

  return observer;
};

export default useScrollObserver;

import React, { useState } from 'react';
import st from './index.module.css';
import { Button } from '../../../ui';
import Offer from '../../../newOfferModal';

const MeetTeam = props => {
  const [showGetProposal, setGetProposal] = useState(false);

  const hideGetProposal = () => {
    if (document.getElementById('form-offer')) document.getElementById('form-offer').reset();
    setGetProposal(false);
    document.body.classList.remove('overflow-hidden');
    document.documentElement.classList.remove('overflow-hidden');
  };

  const setHidden = () => {
    document.body.classList.add('overflow-hidden');
    document.documentElement.classList.add('overflow-hidden');
  };

  return (
    <div className="bg-[#F3F2F8] md:pb-[48px] pb-[56px] md:pt-[128px] pt-[90px] relative px-[10px]">
      <div className="flex flex-row md:gap-[30px] gap-[8px] absolute top-[48px] pl-[18px] pr-[28px] md:left-[50%] md:translate-x-[-50%]">
        <div className="bg-[#C7B6F3] md:rounded-tr-[50px] md:rounded-bl-[50px] rounded-tr-[30px] rounded-bl-[30px] pt-[14px] overflow-hidden">
          <img src="/images/home/teem/lilit.png" alt="lilit" />
        </div>
        <div className="bg-[#C7B6F3] md:rounded-tl-[50px] md:rounded-br-[50px] rounded-tl-[30px] rounded-br-[30px] pt-[14px] overflow-hidden">
          <img src="/images/home/teem/anahit.png" alt="lilit" />
        </div>
        <div className="bg-[#C7B6F3] md:rounded-tl-[50px] md:rounded-br-[50px] rounded-tl-[30px] rounded-br-[30px] pt-[14px] overflow-hidden">
          <img src="/images/home/teem/sey.png" alt="lilit" />
        </div>
        <div className="bg-[#C7B6F3] md:rounded-tr-[50px] md:rounded-bl-[50px] rounded-tr-[30px] rounded-bl-[30px] pt-[14px] overflow-hidden">
          <img src="/images/home/teem/elina.png" alt="lilit" />
        </div>
      </div>
      <div className={`${st.teem_card} md:w-[920px] mx-auto md:gap-[35px] gap-[24px]`}>
        <div className="flex flex-col md:gap-[24px] gap-[16px] items-center">
          <h2 className="flex md:justify-center font-[600] text-[44px] leading-[72px] text-black">Meet Team</h2>

          <p className="font-[500] text-[24px] md:text-[32px] md:leading-[39px] leading-[29px] text-center">
            Our professional team can cover the entire digital marketing cycle and provide real, measurable results.
          </p>
        </div>
        <div
          className="md:flex hidden md:w-[256px] w-full items-center justify-center"
          onClick={() => {
            setGetProposal(true);
            setHidden();
          }}
        >
          <Button variant="primary" className={`w-[256px] ${st.pulse}`}>
            Get a Quote
          </Button>
        </div>
        <a href="/proposals/get-a-proposal" rel="noreferrer" className="flex md:hidden md:w-[210px] w-full items-center justify-center">
          <Button variant="primary" className={`w-[256px] ${st.pulse}`}>
            Get a Quote
          </Button>
        </a>
      </div>
      <div className="proposals">
        <Offer country={props.country} id={'form-offer'} show={showGetProposal} hide={hideGetProposal} title={''} offerPrice={0} offerDuration={0} />
      </div>
    </div>
  );
};

export default MeetTeam;

import React, { useEffect, useState } from 'react';

import { Button } from '../../../ui';
import st from './index.module.css';

import experts from './icons/experts.json';
import record from './icons/record.json';
import reviews from './icons/reviews.json';
import technology from './icons/technology.json';
import InfoItem from './InfoItem';
import classNames from 'classnames';

import Offer from '../../../newOfferModal';

const data = [
  {
    icon: experts,
    header: '40+ Creative Specialists',
  },
  {
    icon: record,
    header: '100% Custom Solutions',
  },
  {
    icon: reviews,
    header: 'Free Unlimited Revisions',
  },
  {
    icon: technology,
    header: 'In-House Technology',
  },
];

const Hero = props => {
  const [showGetProposal, setGetProposal] = useState(false);

  useEffect(() => {
    const videoElement = document.querySelector('video');
    videoElement.play();
  }, []);

  const hideGetProposal = () => {
    if (document.getElementById('form-offer')) document.getElementById('form-offer').reset();
    setGetProposal(false);
    document.body.classList.remove('overflow-hidden');
    document.documentElement.classList.remove('overflow-hidden');
  };

  const setHidden = () => {
    document.body.classList.add('overflow-hidden');
    document.documentElement.classList.add('overflow-hidden');
  };

  return (
    <div className="bg-[#EEEAF9] overflow-hidden md:pt-[102px] pt-[48px] relative">
      <div
        className={classNames(
          'absolute top-[50%] right-0 bg-[#EEEAF9] hidden md:flex flex-col items-center gap-[24px] pl-[16px] pr-[48px] py-[16px] rounded-[10px_0px_0px_10px] transform -translate-y-1/2 ',
          `${st.social_blok}`
        )}
      >
        <a
          className="h-[24px] w-[12px] flex items-center justify-center"
          target="_blank"
          rel="noopener noreferrer nofollow"
          ariaLabel="Facebook"
          href="https://www.facebook.com/weprodigi"
        >
          <img src="/images/icons/home/fb.svg" width={24} height={24} layout="intrinsic" alt="facebook" />
        </a>
        <a className="" target="_blank" rel="noopener noreferrer nofollow" ariaLabel="Behance" href="https://www.behance.net/weprodigiagency">
          <img src="/images/icons/home/behance.svg" width={24} height={24} layout="intrinsic" alt="behance" />
        </a>
        <a className="" target="_blank" rel="noopener noreferrer nofollow" ariaLabel="Youtube" href="https://www.youtube.com/@weprodigi">
          <img src="/images/icons/home/youtube.svg" width={24} height={24} layout="intrinsic" alt="youtube" />
        </a>
        <a className="" target="_blank" rel="noopener noreferrer nofollow" ariaLabel="Linkedin" href="https://www.pinterest.com/prodigiagency/">
          <img src="/images/icons/home/pinterest.svg" width={24} height={24} layout="intrinsic" alt="linkedin" />
        </a>

        <a className="" target="_blank" rel="noopener noreferrer nofollow" ariaLabel="Linkedin" href="https://www.linkedin.com/company/pro.digi/">
          <img src="/images/icons/home/linkedin.svg" width={24} height={24} layout="intrinsic" alt="linkedin" />
        </a>

        <a className="" target="_blank" rel="noopener noreferrer nofollow" ariaLabel="Twitter" href="https://x.com/weprodigi?lang=en">
          <img src="/images/icons/home/twitter.svg" width={24} height={24} layout="intrinsic" alt="twitter" />
        </a>
        <a className="" target="_blank" rel="noopener noreferrer nofollow" ariaLabel="Instagram" href="https://www.instagram.com/weprodigi_agency">
          <img src="/images/icons/home/insta.svg" width={24} height={24} layout="intrinsic" alt="instagram" />
        </a>
      </div>

      <div className="container">
        <div className="flex flex-col md:gap-[57px]">
          <div className="flex md:flex-row md:gap-[20px] gap-[48px] flex-col-reverse">
            <div className="flex flex-col md:gap-[39px] gap-[24px] justify-center max-w-[446px] w-full md:pb-0 pb-[48px]">
              <div className="flex flex-col gap-[24px]">
                <h1 className="font-[700] text-[36px] leading-[44px] text-black uppercase">
                  Full service digital <br /> marketing agency
                </h1>
                <p className="font-[400] text-[18px] leading-[22px] text-[#6C6E70]">that brings results</p>
              </div>
              <div className="flex md:gap-[10px] gap-[14px] md:flex-row flex-col">
                <a
                  href="https://calendly.com/info-weprodigi/call-with-account-team-video-production"
                  className="flex md:w-[210px] w-full items-center justify-center"
                  target="_blank"
                >
                  <Button variant="primaryVideo" className="md:w-[210px] w-full hover:md:w-[200px]">
                    Schedule a call
                  </Button>
                </a>
                <div
                  className="md:flex hidden md:w-[210px] w-full items-center justify-center"
                  onClick={() => {
                    setGetProposal(true);
                    setHidden();
                  }}
                >
                  <Button variant="blackVideo" className="md:w-[210px] w-full hover:md:w-[200px]">
                    Get a Quote
                  </Button>
                </div>

                <a href="/proposals/get-a-proposal" rel="noreferrer" className="flex md:hidden md:w-[210px] w-full items-center justify-center">
                  <Button variant="blackVideo" className="md:w-[210px] w-full hover:md:w-[200px]">
                    Get a Quote
                  </Button>
                </a>
              </div>
            </div>

            <div className=" border w-full md:h-[393px] h-[356px] overflow-hidden  rounded-[100px_0px_100px_0px]">
              <video className="w-full h-full object-cover" src="/images/home/hero.mp4" muted autoPlay loop preload="auto" playsInline></video>
            </div>
          </div>

          <div className=" flex-row gap-[37px] py-[42px] md:flex hidden">
            {data.map((item, index) => (
              <InfoItem key={index} icon={item.icon} header={item.header} />
            ))}
          </div>
        </div>
      </div>

      <div className="proposals">
        <Offer country={props.country} id={'form-offer'} show={showGetProposal} hide={hideGetProposal} title={''} offerPrice={0} offerDuration={0} />
      </div>
    </div>
  );
};

export default Hero;

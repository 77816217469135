import st from '../index.module.css';
export const services = {
  SMM: {
    id: '',
    from: '',
    label: 'Get SMM Pricing',
    title: 'How can we help your business succeed with SMM Services?',
    getPricing: '/social-media-marketing/proposals/smm-pricing',
    seeFullService: '/social-media-marketing',
    info: (
      <>
        Strengthen your brand’s image through the <span className="text-[#0052B4] font-bold">right social media channels.</span>
      </>
    ),
    details: [
      {
        title: 'Description',
        description: `Social media platforms are vital for digital marketing services, helping to boost customer loyalty, increase brand awareness, provide an omnichannel connection with your target customers, raise customer satisfaction, and build brand trust. To excel in Social Media Marketing services, one must first possess current marketing expertise and be able to generate relevant content that meets customer needs.  Our social media marketing specialists are FB official certified professionals with relevant skills, expertise, and the latest tools to provide the best SMM services. We have worked with over 90 SMM partners in 12 industries and received countless testimonials and feedback on independent review sites as a testament to our expertise. For added value, we have an in-house production team generating unique content that can guarantee your brand’s competitive advantage on social media. To get the best results from your investment, we involve a social media advertising specialist in the process who promises maximum performance from your properly targeted pages. You will have the return on investments in SMM in about two months, but the results will exceed your expectations.`,
      },
      {
        title: 'Pricing Overview',
        description: (
          <div className="flex flex-col gap-[24px]">
            Custom SMM services that bring results.
            <ul className={`${st['custom-list']}`}>
              <li>Starting at $1200 </li>
              <li>Customized visual content (images, videos)</li>
              <li>SMM strategy</li>
              <li>Monthly reporting and analysis</li>
            </ul>
          </div>
        ),
      },
      {
        title: 'Benefits',
        description: (
          <ul className={`${st['custom-list']}`}>
            <li>
              <b>Targeted Reach:</b> Reach your target audience effectively with data-driven services based on your business goals.
            </li>
            <li>
              <b>Quick Response:</b> Stay ahead with dynamic, real-time campaigns targeting your audience instantly.
            </li>
            <li>
              <b>Affordable Options:</b> Enjoy budget-friendly services designed to boost traffic as expected.
            </li>
            <li>
              <b>Dedicated Support: </b> Work with a social media expert guiding you every step of the way.
            </li>
            <li>
              <b>Transparent Reporting: </b>Access real-time performance results and ROI analysis.
            </li>
          </ul>
        ),
      },
    ],
  },
  'Paid Advertising': {
    id: 'form-ppc',
    from: 'contact',
    label: '',
    title: 'How can we help your business succeed with paid advertising?',
    getPricing: '/paid-advertising-services/get-proposal',
    seeFullService: '/paid-advertising-services',
    info: (
      <>
        Enhance the quality of inbound leads and <span className="text-[#0052B4] font-bold"> grow audience engagement.</span>
      </>
    ),
    details: [
      {
        title: 'Description',
        description: `Our digital marketing services include paid ads services, one of the leading solutions today. For the growth of your business, driving more traffic to your website, generating more leads, increasing conversion rates, and improving customer engagement and online visibility, then this subfield of digital marketing services is the most effective and practical way. Running internet marketing services with us means working with the Google Partner agency. Being among the first to acquire the latest trends, our experts are always “on the wave” to create efficient campaigns. To show you the results of our actions, we provide regular and 100% transparent reporting to display the performance of online marketing campaigns, achieved results, and how much of your investment is converted into revenue.`,
      },
      {
        title: 'Pricing Overview',
        description: (
          <div className="flex flex-col gap-[24px]">
            Paid advertising services that drive more leads.
            <ul className={`${st['custom-list']}`}>
              <li>Starting at $3000 </li>
              <li>Guaranteed leads or purchases</li>
              <li>Industry analysis</li>
              <li>Add banners creation</li>
            </ul>
          </div>
        ),
      },
      {
        title: 'Benefits',
        description: (
          <ul className={`${st['custom-list']}`}>
            <li>
              <b>Targeted Campaigns:</b> Reach your target audience with data-driven ad placements.
            </li>
            <li>
              <b>Budget Control:</b> Flexible packages designed for different budgets.
            </li>
            <li>
              <b>Real-Time Tracking:</b> Track performance and ROI with transparent reports.
            </li>
            <li>
              <b>Expert Strategy:</b> Campaigns for maximum impact optimized by experienced specialists.
            </li>
          </ul>
        ),
      },
    ],
  },
  SEO: {
    id: '',
    from: '',
    label: '',
    title: 'How can we help your business succeed with SEO services?',
    getPricing: '/seo/seo-form',
    seeFullService: '/seo/professional-seo-services',
    info: (
      <>
        Achieve <span className="text-[#0052B4] font-bold"> strategic growth</span> and improve your{' '}
        <span className="text-[#0052B4] font-bold"> website’s organic traffic.</span>
      </>
    ),
    details: [
      {
        title: 'Description',
        description: `To improve search engine placement or ranking, an organization can implement various SEO services. Not only does it drive up website performance and page rank, but it also strengthens the company's reputation. Our SEO experts are certified personnel with sophisticated skill sets, experience, and advanced instruments. Having worked with over 100 customers across different sectors, we have received the highest ratings for our approaches to keyword planning, on-site optimization, and backlinks. We guarantee that we can work on your business to enhance your competitiveness with an emphasis on developing SEO content and measuring website success. Make your investments in words in the form of tangible results within a few months!`,
      },
      {
        title: 'Pricing Overview',
        description: (
          <div className="flex flex-col gap-[24px]">
            Professional SEO services for measurable success.
            <ul className={`${st['custom-list']}`}>
              <li>Starting at $1300</li>
              <li>Keyword Research & Selection</li>
              <li>Technical Website Analysis & Opportunity Report</li>
              <li>On-page, off-page, and technical SEO</li>
            </ul>
          </div>
        ),
      },
      {
        title: 'Benefits',
        description: (
          <ul className={`${st['custom-list']}`}>
            <li>
              <b>Higher Rankings:</b> Get visibility on search engines with proven strategies.
            </li>
            <li>
              <b>Comprehensive Analysis:</b> Discover opportunities with detailed audits and keyword research.
            </li>
            <li>
              <b>Customized Plans:</b> Flexible options based on your industry, goals, and budget.
            </li>
            <li>
              <b> Performance Tracking: </b> Transparent reports to measure progress and ROI.
            </li>
          </ul>
        ),
      },
    ],
  },
  'Video Production': {
    id: '',
    from: '',
    label: '',
    title: 'How can we help your business succeed with Video Production?',
    getPricing: '/video-production/get-pricing',
    seeFullService: '/video-production',
    info: (
      <>
        Engage customers with <span className="text-[#0052B4] font-bold"> impactful videos</span> that showcase your brand’s essence.
      </>
    ),
    details: [
      {
        title: 'Description',
        description: `Our video production services are one of the key elements of our full digital marketing services. Through our video production services, we assist businesses from multiple industries in meeting their business needs, including marketing, sales, education, HR management, customer support, and non-profit organizations involved in education, entertainment, and other fields. Our team specializes in producing explainers, how-tos, educational, commercial, product videos, social media videos, webinars, and vlog videos. Our production services include live-action videos (shooting, editing, and mixing media) and animation-style videos with 2D and 3D, Whiteboard, and Motion Infographics animation. Approaching the video production service with great responsibility, we cover all the processes with in-house specialists. We own and produce an exclusive voice-over library, ensuring that you don't have to worry about anything. Our clients use our videos for multiple purposes and various digital marketing channels. Their efficiency is proven by over 120 client testimonials sharing their experience and goals reached with tailor-made videos.`,
      },
      {
        title: 'Pricing Overview',
        description: (
          <div className="flex flex-col gap-[24px]">
            Full service video production that delivers results and impact.
            <ul className={`${st['custom-list']}`}>
              <li>Starting at $1000</li>
              <li> A script, storyboard, human voiceover, and sound design included</li>
              <li>Milestone-based payments</li>
              <li>Unlimited revisions at each stage</li>
            </ul>
          </div>
        ),
      },
      {
        title: 'Benefits',
        description: (
          <ul className={`${st['custom-list']}`}>
            <li>
              <b>Quick Turnaround:</b> Get your video in 4 weeks, with urgent orders welcome.
            </li>
            <li>
              <b>Creative Expertise:</b> Our passionate team creates impactful scripts and handles shooting and editing.
            </li>
            <li>
              <b>Flexible Pricing:</b> We offer flexible options to suit any budget.
            </li>
            <li>
              <b>Clear Process:</b> Receive real-time updates and provide feedback at key milestones.
            </li>
          </ul>
        ),
      },
    ],
  },
  'Web Development': {
    id: 'form-web',
    from: '',
    label: 'Web Development',
    title: 'How can we help your business succeed with Web Development Services?',
    getPricing: '/get-proposal?t=web',
    seeFullService: '',
    info: (
      <>
        A strategic approach to
        <span className="text-[#0052B4] font-bold"> web development</span> and design for fast and scalable solutions.
      </>
    ),
    details: [
      {
        title: 'Description',
        description: `In the digital environment, the website is a powerful tool to create brand awareness and showcase your products or services to prospective customers, especially in the early stages of business development. Our custom web development services can certainly help you build your professional presence with a website. Our team of engineers, proficient in PHP, ASP, and HTML5, will handle a custom website development and deployment, making it completely responsive for further marketing efforts. With over 10 years of experience in web design and development and a core team of developers working side by side with marketers, they know how to build a website and stress out the functionality that will later be used to drive traffic and higher engagement and retention rates. The success of your website depends on its design, functionality, and powerful SEO optimization to make the website work for your goals. After the deployment, we will step into the second phase, which is attracting visitors to your website through different marketing channels.`,
      },
      {
        title: 'Pricing Overview',
        description: (
          <div className="flex flex-col gap-[24px]">
            Professional web development service that drives performance.
            <ul className={`${st['custom-list']}`}>
              <li>Starting at $10000</li>
              <li>Unlimited revisions in each stage </li>
              <li>Milestone-based payments </li>
              <li>Customised approach</li>
            </ul>
          </div>
        ),
      },
      {
        title: 'Benefits',
        description: (
          <ul className={`${st['custom-list']}`}>
            <li>
              <b>Custom Solutions:</b> We provide websites tailored to your business needs and goals.
            </li>
            <li>
              <b>Responsive Design:</b> Ensure your site looks great on desktops, laptops, and mobile devices.
            </li>
            <li>
              <b>SEO-Ready Builds:</b> Designed for optimal SEO to boost organic traffic.
            </li>
            <li>
              <b>Ongoing Support:</b> Get continuous support to keep your site running smoothly.
            </li>
          </ul>
        ),
      },
    ],
  },
  Design: {
    id: 'form-web',
    from: '',
    label: 'Design Services',
    title: 'How can we help your business succeed with Design Services?',
    getPricing: '/get-proposal?t=design',
    seeFullService: '/design',
    info: (
      <>
        Leave a lasting impression with an <span className="text-[#0052B4] font-bold"> exceptional design</span> that conveys your brand message.
      </>
    ),
    details: [
      {
        title: 'Description',
        description: `With over a decade of design experience, we provide 100% custom design services for online and offline uses. We support small and medium-sized businesses, non-profit organizations, and educational institutions, providing web, print, graphic, and logo design services to fulfill their business needs for digital marketing services, sales, and education initiatives. Focusing on a design that is not only beautiful and catchy but also purpose-driven helps us create targeted and efficient marketing campaigns that best fit your audience and its needs. We leverage our marketing expertise and design to reach your goals and provide maximum engagement. Working with over 25 production software (including Adobe Photoshop, Illustrator, Animator, After Effects, InDesign, Figma, Corel Draw, etc.) and having fulfilled projects in more than 30 industries, we are confident in our skills and expertise in delivering top-notch design ideas customized for your business and your audience needs. Our focus is on creating an on-brand design and avoiding templates, saving you time. `,
      },
      {
        title: 'Pricing Overview',
        description: (
          <div className="flex flex-col gap-[24px]">
            Professional screencast videos that demonstrate your product.
            <ul className={`${st['custom-list']}`}>
              <li>Starting at $500</li>
              <li>Unlimited revisions </li>
              <li>Customized design solutions </li>
              <li>Flexible payment methods</li>
            </ul>
          </div>
        ),
      },
      {
        title: 'Benefits',
        description: (
          <ul className={`${st['custom-list']}`}>
            <li>
              <b>Tailored Creativity:</b> Custom designs that capture your company's essence.
            </li>
            <li>
              <b>Quick Turnaround:</b> Receive high-quality photos in 7-10 days, with rush delivery options available.
            </li>
            <li>
              <b>Flexible Packages:</b> Choose designs that fit your budget and project size.
            </li>
            <li>
              <b>Dedicated Designer:</b> Work closely with a designer to bring your vision to life.
            </li>
          </ul>
        ),
      },
    ],
  },
};

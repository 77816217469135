import React, { useState } from 'react';
import classNames from 'classnames';
import navigation from './data/navigation.json';
import { services } from './data/services';
import ServicesDetails from './ServicesDetails';
import { Button } from '../../../ui';
import st from './index.module.css';

import GetProposal from '../../../proposal/ppc/GetProposal';

import dynamic from 'next/dynamic';

const SMMPricing = dynamic(() => import('../../../smm/SMMPriceing'));

const OurServices = props => {
  const [activeTab, setActiveTab] = useState('SMM');

  const [showGetPriceSMM, setShowGetPriceSMM] = useState(false);
  const [showGetPrice, setShowGetPrice] = useState(false);

  const hideGetPrice = () => {
    setShowGetPrice(false);
    document.body.classList.remove('overflow-hidden');
    document.documentElement.classList.remove('overflow-hidden');
  };

  const hideGetPriceSMM = () => {
    if (document.getElementById('offer-form-smm')) document.getElementById('offer-form-smm').reset();
    setShowGetPriceSMM(false);
    document.body.classList.remove('overflow-hidden');
    document.documentElement.classList.remove('overflow-hidden');
  };

  const setHidden = () => {
    document.body.classList.add('overflow-hidden');
    document.documentElement.classList.add('overflow-hidden');
  };

  return (
    <div className="md:bg-[#F3F2F8] bg-white md:py-[48px] pt-[48px]" id="our-services">
      <div className="container">
        <div className="flex flex-col gap-[48px]">
          <div className="flex md:flex-row flex-col md:gap-[30px] gap-[24px]">
            <div
              className="py-[16px] px-[27px] bg-[#EEEAF9] max-w-[350px] w-full rounded-[10px] h-[481px] md:block hidden"
              style={{ position: 'sticky', top: 0 }}
            >
              <ul className="flex flex-col gap-[12px]">
                {navigation.map((item, index) => (
                  <div key={index} className={classNames(index < navigation.length - 1 && 'border-b-[1px] border-white pb-[12px]')}>
                    <li
                      className={classNames(
                        'text-[18px] font-medium leading-[22px] py-[16px] px-[26px] transition-colors rounded-[5px] cursor-pointer text-black whitespace-nowrap',
                        {
                          'bg-[#C7B6F3]': activeTab === item.label,
                          [st.button_animation]: activeTab !== item.label,
                        }
                      )}
                      onClick={() => setActiveTab(item.label)}
                    >
                      {item.label}
                    </li>
                  </div>
                ))}
              </ul>
            </div>
            <div className={classNames('md:hidden flex flex-row overflow-auto', st.hide_scrollbar)}>
              {navigation.map((tab, index) => (
                <div key={index} className="px-[12px]">
                  <button
                    key={tab.label}
                    className={`${st.tab_button} ${activeTab === tab.label ? `${st.active_tab}` : `${st.hover_tab}`} whitespace-nowrap`}
                    onClick={() => {
                      setActiveTab(tab.label);
                    }}
                  >
                    {tab.label}
                  </button>
                </div>
              ))}
            </div>
            <div className="flex flex-col gap-[8px] w-full overflow-hidden">
              <ServicesDetails data={services[activeTab]} activeTab={activeTab} />
              <div
                className={classNames(
                  'md:flex hidden rounded-[5px] gap-[44px] bg-[#D6E8FF] py-[22px] px-[26px] w-full justify-between items-center relative'
                )}
              >
                <div className={`absolute w-full h-full ${st.background_shine}`}></div>
                <p className="text-[32px] leading-[39px] text-left font-medium relative z-[10]"> {services[activeTab].info}</p>
                <div className="flex flex-col justify-center gap-[16px] relative z-[10]">
                  {(activeTab === 'SEO' || activeTab === 'Video Production') && (
                    <a
                      href={services[activeTab].getPricing}
                      rel="noreferrer"
                      className="md:flex hidden md:w-[210px] w-full items-center justify-center"
                    >
                      <Button variant="primaryVideo" className="md:w-[210px] w-full hover:md:w-[200px]">
                        Get Pricing
                      </Button>
                    </a>
                  )}
                  {activeTab === 'SMM' && (
                    <div
                      className="flex md:w-[210px] w-full items-center justify-center"
                      onClick={() => {
                        setHidden();
                        setShowGetPriceSMM(true);
                      }}
                    >
                      <Button variant="primary" className="md:w-[210px] w-full hover:md:w-[200px]">
                        Get Pricing
                      </Button>
                    </div>
                  )}
                  {(activeTab === 'Web Development' || activeTab === 'Paid Advertising' || activeTab === 'Design') && (
                    <div
                      className="flex md:w-[210px] w-full items-center justify-center"
                      onClick={() => {
                        setHidden();
                        setShowGetPrice(true);
                      }}
                    >
                      <Button variant="primary" className="md:w-[210px] w-full hover:md:w-[200px]">
                        Get Pricing
                      </Button>
                    </div>
                  )}
                  {activeTab !== 'Web Development' && (
                    <a href={services[activeTab].seeFullService} className="flex md:w-[210px] w-full items-center justify-center">
                      <Button variant="blueLine" className="md:w-[210px] w-full hover:md:w-[200px] !bg-white">
                        See Full Service
                      </Button>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex md:hidden flex-col relative overflow-hidden rounded-[5px] gap-[32px] bg-[#D6E8FF] mt-[24px] pt-[24px] pb-[32px] px-[28px] w-full justify-center">
        <div className={`absolute w-full h-full  ${st.background_shine}`}></div>
        <p className="text-[32px] leading-[39px] font-medium relative z-[10]"> {services[activeTab].info}</p>
        <div className="flex flex-col justify-center gap-[16px] relative z-[10]">
          <a href={services[activeTab].getPricing} rel="noreferrer" className="flex md:hidden md:w-[210px] w-full items-center justify-center">
            <Button variant="primaryVideo" className="md:w-[210px] w-full hover:md:w-[200px]">
              Get Pricing
            </Button>
          </a>

          {activeTab !== 'Web Development' && (
            <a href={services[activeTab].seeFullService} className="flex md:w-[210px] w-full items-center justify-center">
              <Button variant="blueLine" className="md:w-[210px] w-full hover:md:w-[200px] !bg-white">
                See Full Service
              </Button>
            </a>
          )}
        </div>
      </div>

      <div className="proposals">
        <GetProposal
          id={services[activeTab].id}
          country={props.country}
          show={showGetPrice}
          hide={hideGetPrice}
          currency={props.currency}
          from={services[activeTab].from}
          title={services[activeTab].label}
        />
        <SMMPricing step={1} country={props.country} show={showGetPriceSMM} title="Get SMM Pricing" hide={hideGetPriceSMM} />
      </div>
    </div>
  );
};

export default OurServices;

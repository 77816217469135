import { Player } from '@lottiefiles/react-lottie-player';

const InfoItem = ({ icon, header }) => {
  return (
    <div className="flex flex-row gap-[16px] items-center">
      <Player autoplay loop src={icon} style={{ height: '54px', width: '100%' }}></Player>
      <p className="text-[18px] leading-[22px] font-medium text-black">{header}</p>
    </div>
  );
};

export default InfoItem;

import React from 'react';
import CasesSlider from './CasesSlider';
import ArrowLink from '../../../ui/ArrowLink';

const Cases = () => {
  return (
    <div className="bg-[#F3F2F8]">
      <div className="container pt-[48px] md:pb-[26px] pb-[48px]">
        <div className="flex gap-[48px] flex-col ">
          <h2 className="flex md:justify-center md:items-center font-[600] text-[32px] ml-[13px] md:text-[34px] leading-[39px] md:leading-[42px] text-black">
            Featured Cases
          </h2>
          <div className="md:flex hidden">
            <CasesSlider />
          </div>
        </div>
        <div className="md:flex hidden justify-end w-full my-[20px]">
          <ArrowLink link="https://weprodigi.com/main/cases" text="More cases" />
        </div>
      </div>
      <div className="flex md:hidden ml-[15px] pb-[48px]">
        <CasesSlider />
      </div>
    </div>
  );
};

export default Cases;

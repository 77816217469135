import React, { useState, useEffect } from 'react';
import st from './index.module.css';

const nav = [
  {
    id: 'our-services',
    label: 'Our Services',
  },
  {
    id: 'our-expertise',
    label: 'Our Expertise',
  },
  {
    id: 'our-results',
    label: 'Our Results',
  },
  {
    id: 'inside-prodigi',
    label: 'Inside Prodigi',
  },
];

const Navigation = () => {
  const [activeTab, setActiveTab] = useState('Our Services');

  useEffect(() => {
    const handleScroll = () => {
      nav.forEach(item => {
        const section = document.getElementById(item.id);
        if (section) {
          const rect = section.getBoundingClientRect();

          if (rect.top <= 80 && rect.bottom > 0) {
            setActiveTab(item.label);
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = id => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="pt-[24px] pb-[8px] flex justify-center">
      <div className={`${st.home_navigation}`}>
        {nav.map(item => (
          <div
            key={item.id}
            onClick={() => {
              handleScroll(item.id), setActiveTab(item.label);
            }}
            className={`${st.tab_button} ${activeTab === item.label ? `${st.active_tab}` : `${st.hover_tab}`}`}
          >
            {item.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Navigation;

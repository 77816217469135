import React from 'react';
import CountUp from 'react-countup';
import AboutClientsSlider from './AboutClientsSlider';
import AboutClientsSliderMobile from './AboutClientsSliderMobile';
import useInView from '../../../../hooks/useInView';

const AboutClients = () => {
  const [countRef, countInView] = useInView({ threshold: 0.3 });

  return (
    <div className="bg-white pb-[48px] pt-[87px] overflow-hidden" id="our-results">
      <div className="container">
        <div className="flex md:flex-row flex-col gap-[30px]">
          <div ref={countRef} className="flex flex-col gap-[48px] max-w-[445px]">
            <div className="flex flex-row relative">
              <p className="text-[#D9A8E5] md:text-[268px] text-[211px] leading-[220px] font-semibold">
                {countInView && (
                  <CountUp start={0} end={113} suffix={''} duration={2.75}>
                    113
                  </CountUp>
                )}
              </p>
              <p className="text-[#0052B4] md:text-[113px] text-[81px] leading-[72px] font-semibold relative top-[-40px] right-0">+</p>
            </div>
            <h3 className="text-[33px] leading-[42px] font-semibold text-black">Successful collaborations with companies in various industries․</h3>
          </div>
          <div className="w-[635px] mt-[45px] md:block hidden">
            <AboutClientsSlider />
          </div>
          <div className="max-w-[344px] md:hidden block">
            <AboutClientsSliderMobile />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutClients;

import React, { useRef } from 'react';
import Slider from 'react-slick';
import AppearencesInMediaCard from './AppearencesInMediaCard';
import 'slick-carousel/slick/slick.css';

import { ArrowButton } from '../../../ui';

function SampleNextArrow(props) {
  const { onClick } = props;

  return (
    <ArrowButton
      className="shadow-[0px_4px_7.1px_0px_rgba(0,0,0,0.17)] -right-[41px] top-[50%] absolute translate-y-[-50%]"
      shape="circle"
      direction="right"
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;

  return (
    <ArrowButton
      className="shadow-[0px_4px_7.1px_0px_rgba(0,0,0,0.17)] -left-[40px] top-[50%] absolute translate-y-[-50%]"
      shape="circle"
      direction="left"
      onClick={onClick}
    />
  );
}

const AppearencesInMediaSlider = () => {
  const slider = useRef();

  const settings = {
    infinite: true,
    speed: 500,
    swipe: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    dots: false,
    waitForAnimate: false,
    responsive: [
      {
        breakpoint: 1215,
        settings: {
          variableWidth: true,
          slidesToShow: 1,
          arrows: false,
          dots: true,
          dotsClass: 'custom-dots',
          centerPadding: '15px',
        },
      },
    ],
  };

  return (
    <>
      <style>
        {`
          .home-media-slider .slick-list {
            overflow: hidden;  
          }
          .home-media-slider .slick-track {
            display: flex;
            align-items: center;  
          }
          .home-media-slider .slick-slide {
            outline: none;
          }
          .home-media-slider .slick-slide div {
            outline: none;
          }
          .home-media-slider .custom-dots {
            display: flex !important;
            justify-content: center;
          }
          .home-media-slider .custom-dots li {
            position: relative;
            margin: 0 3px;
            margin-top: 20px;
            width: 50px;
            height: 2px;
            background-color: #C4C4C4;
            overflow: hidden;
          }
          .home-media-slider .custom-dots li.slick-active {
            background-color: #D9A8E5;
          }
          .home-media-slider .custom-dots li button {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0;
          }  
        `}
      </style>
      <Slider ref={slider} {...settings} className={'home-media-slider w-full h-full relative'}>
        <div className="px-[15px] pr-[15px]">
          <AppearencesInMediaCard
            site="168.am"
            name="168"
            url="https://en.168.am/2020/06/04/38006.html"
            title="Social Media User First: Strategy for Strong User Engagement"
          />
        </div>
        <div className="px-[15px] pr-[15px]">
          <AppearencesInMediaCard
            site="aravot.am"
            url="https://en.aravot.am/2020/07/07/259428/"
            name="aravot"
            title="Why do we love watching videos so much?"
          />
        </div>
        <div className="px-[15px] pr-[15px]">
          <AppearencesInMediaCard
            site="Bignewsnetwork.com"
            url="https://www.bignewsnetwork.com/news/265623046/prodigi-women-led-businesses-are-possible-in-armenia"
            name="mexico-news"
            title="ProDigi: Women-led businesses are possible in Armenia"
          />
        </div>
        <div className="px-[15px] pr-[15px]">
          <AppearencesInMediaCard
            site="168.am"
            name="168"
            url="https://en.168.am/2020/06/04/38006.html"
            title="Social Media User First: Strategy for Strong User Engagement"
          />
        </div>
        <div className="px-[15px] pr-[15px]">
          <AppearencesInMediaCard
            site="aravot.am"
            url="https://en.aravot.am/2020/07/07/259428/"
            name="aravot"
            title="Why do we love watching videos so much?"
          />
        </div>
        <div className="px-[15px] pr-[15px]">
          <AppearencesInMediaCard
            site="Bignewsnetwork.com"
            url="https://www.bignewsnetwork.com/news/265623046/prodigi-women-led-businesses-are-possible-in-armenia"
            name="mexico-news"
            title="ProDigi: Women-led businesses are possible in Armenia"
          />
        </div>
      </Slider>
    </>
  );
};

export default AppearencesInMediaSlider;

import React, { useState } from 'react';

import ServicesItem from './ServicesItem';

const ServicesDetails = ({ data, activeTab }) => {
  const { title, details } = data;

  const [openIndex, setOpenIndex] = useState(0);

  const handleClick = index => {
    setOpenIndex(index === openIndex ? null : index);
  };

  return (
    <div className="flex flex-col w-full gap-[48px] flex-1">
      <div className="flex md:flex-row flex-col w-full justify-between md:gap-0 gap-[24px]">
        <h2 className="md:text-[30px] text-[24px] font-semibold leading-[29px] md:leading-[39px]  text-left text-black">{title}</h2>
      </div>
      <div className="flex flex-col gap-[8px] w-full">
        {details.map((item, index) => (
          <ServicesItem
            key={index}
            activeTab={activeTab}
            title={item.title}
            description={item.description}
            isOpen={openIndex === index}
            onClick={() => handleClick(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default ServicesDetails;

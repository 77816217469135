import React from 'react';
import SkeletonAnimation from '../../../../hoc/skeletonAnimation/SkeletonAnimation';

const ArticalSkeleton = ({ skeletonClass }) => {
  return (
    <div className="flex md:flex-row flex-col gap-[30px] relative">
      <div className={`bg-[#E9E9E9] md:w-[255px] md:h-[158px] h-[213px] w-full rounded-[10px] overflow-hidden ${skeletonClass}`}></div>
      <div className="flex-1 flex justify-between md:w-[255px] md:h-[158px] h-[213px] w-full flex-col gap-[32px]">
        <div className="flex flex-col gap-[16px]">
          <p className={`w-full h-[22px] bg-[#E9E9E9] ${skeletonClass}`}></p>
          <p className={`w-full h-[66px] bg-[#E9E9E9] ${skeletonClass}`}></p>
        </div>
        <div className="flex flex-row gap-[8px]">
          <p className={`w-full h-[16px] bg-[#E9E9E9] ${skeletonClass}`}></p>
        </div>
      </div>
    </div>
  );
};

export default SkeletonAnimation(ArticalSkeleton);

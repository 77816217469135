import LazyLoad from 'react-lazyload';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

const AboutClientsSliderMobile = () => {
  const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    vertical: true,
    infinite: true,
    swipe: false,
    touchMove: false,
    autoplay: true,
    verticalSwiping: false,
    speed: 1000,
    autoplaySpeed: 0,
  };

  return (
    <LazyLoad height={200} once>
      <style jsx global>{`
        /* Smooth scrolling animation for the slider */
        @keyframes smoothScroll {
          0% {
            transform: translateY(0);
          }
          100% {
            transform: translateY(-100%);
          }
        }

        .about-clients-mob .slick-track {
          display: flex;
          flex-direction: column;
          gap: 15px;
          animation: smoothScroll 15s linear infinite; /* 15 seconds for one loop */
        }

        /* Duplicate slides to ensure infinite loop effect */
        .about-clients-mob .slick-slide {
          flex-shrink: 0;
          height: 66px; /* Match your slide height */
        }
      `}</style>
      <Slider {...settings} className="about-clients-mob w-full">
        <div className="!flex !flex-row !justify-between !items-center !h-[66px] my-[15px]">
          <img
            src="/images/video-production/logos/civilAvtation.png"
            alt="client"
            className="grayscale opacity-50 w-[139px] h-[36px] object-contain"
          />
          <img src="/images/video-production/logos/bayshore.png" alt="client" className="grayscale opacity-50 w-[134px] h-[35px] object-contain" />
        </div>
        <div className="!flex !flex-row !justify-between !items-center !h-[66px] my-[15px]">
          <img src="/images/video-production/logos/iata.png" alt="client" className="grayscale opacity-50 w-[86px] h-[56px] object-contain" />
          <img src="/images/video-production/logos/kappitaan.png" alt="client" className="grayscale opacity-50 w-[143px] h-[35px] object-contain" />
        </div>

        <div className="!flex !flex-row !justify-between !items-center !h-[66px] my-[15px]">
          <img src="/images/video-production/logos/dcvmn.png" alt="client" className="grayscale opacity-50 w-[135px] h-[36px] object-contain" />

          <img src="/images/video-production/logos/perkinElmer.png" alt="client" className="grayscale opacity-50 w-[106px] h-[46px] object-contain" />
        </div>

        <div className="!flex !flex-row !justify-between !items-center !h-[66px] my-[15px]">
          <img src="/images/video-production/logos/hsbc.png" alt="client" className="grayscale opacity-50 w-[135px] h-[36px] object-contain" />
          <img src="/images/video-production/logos/dnv.png" alt="client" className="grayscale opacity-50 w-[106px] h-[46px] object-contain" />
        </div>
        <div className="!flex !flex-row !justify-between !items-center !h-[66px] my-[15px]">
          <img src="/images/video-production/logos/girp.png" alt="client" className="grayscale opacity-50 w-[75px] h-[31px] object-contain" />
          <img src="/images/video-production/logos/matador.png" alt="client" className="grayscale opacity-50 w-[128px] h-[30px] object-contain" />
        </div>

        <div className="!flex !flex-row !justify-between !items-center !h-[66px] my-[15px]">
          <img src="/images/video-production/logos/atkins.png" alt="client" className="grayscale opacity-50 w-[72px] h-[66px] object-contain" />
          <img src="/images/video-production/logos/lanehelth.png" alt="client" className="grayscale opacity-50 w-[153px] h-[32px] object-contain" />
        </div>
        <div className="!flex !flex-row !justify-between !items-center !h-[66px] my-[15px]">
          <img src="/images/video-production/logos/saferoom.png" alt="client" className="grayscale opacity-50 w-[95px] h-[51px] object-contain" />
          <img src="/images/video-production/logos/vshred.png" alt="client" className="grayscale opacity-50 w-[125px] h-[26px] object-contain" />
        </div>
        <div className="!flex !flex-row !justify-between !items-center !h-[66px] my-[15px]">
          <img src="/images/video-production/logos/pumpables.png" alt="client" className="grayscale opacity-50 w-[118px] h-[23px] object-contain" />
          <img src="/images/video-production/logos/spacewell.png" alt="client" className="grayscale opacity-50 w-[96px] h-[50px] object-contain" />
        </div>
        <div className="!flex !flex-row !justify-between !items-center !h-[66px] my-[15px]">
          <img src="/images/video-production/logos/tapncare.png" alt="client" className="grayscale opacity-50 w-[140px] h-[36px] object-contain" />
          <img src="/images/video-production/logos/cozii.png" alt="client" className="grayscale opacity-50 w-[82px] h-[58px] object-contain" />
        </div>
        <div className="!flex !flex-row !justify-between !items-center !h-[66px] my-[15px]">
          <img src="/images/video-production/logos/ardshin.png" alt="client" className="grayscale opacity-50 w-[85px] h-[56px] object-contain" />
          <img
            src="/images/video-production/logos/mobilityStream.png"
            alt="client"
            className="grayscale opacity-50 w-[168px] h-[30px] object-contain"
          />
        </div>
      </Slider>
    </LazyLoad>
  );
};

export default AboutClientsSliderMobile;

import classNames from 'classnames';

const ArrowLink = props => {
  const { link, text, className } = props;

  return (
    <a
      aria-label="review"
      href={link}
      className={classNames(className, 'group text-[16px] font-medium flex leading-[20px] cursor-pointer')}
      target="_blank"
      rel="noopener noreferrer nofollow"
    >
      <p className="flex flex-row gap-[8px] items-center text-[#0052B4] group-hover:text-black">
        <span> {text} </span>
        <span>
          <svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.232056 10.8988L4.62122 6.5L0.232056 2.10125L1.58331 0.75L7.33331 6.5L1.58331 12.25L0.232056 10.8988Z" fill="currentColor" />
          </svg>
        </span>
      </p>
    </a>
  );
};

export default ArrowLink;

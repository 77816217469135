import React from 'react';

const AppearencesInMediaCard = props => {
  const { site, name, title, url } = props;

  return (
    <a href={url} target="_blank">
      <div className="rounded-[5px] max-w-[260px] md:max-w-[350px] md:h-auto h-[235px] mb-[26px]  flex flex-col justify-between gap-[16px]">
        <div>
          <img src={`/images/seo/${name}.webp`} alt={name} />
        </div>
        <div className={'flex gap-[16px] justify-center items-center '}>
          <div className="h-[46px] w-[46px] bg-white rounded-[50%] flex items-center justify-center ">
            <img src={`/images/seo/logos/${name}.svg`} alt={name} />
          </div>
          <div className={'flex gap-[8px] flex-col flex-1'}>
            <p className={'text-[14px] font-[600] leading-[17px]'}>{title}</p>
            <p className={'text-[#C4C4C4] text-[14px] font-[400] leading-[17px]'}>{site}</p>
          </div>
        </div>
      </div>
    </a>
  );
};

export default AppearencesInMediaCard;

import React, { useRef } from 'react';
import Slider from 'react-slick';
import CasesCard from './CasesCard';
import 'slick-carousel/slick/slick.css';
import useScrollObserver from '../../../../hooks/useScrollObserver';

const CasesSlider = () => {
  const slider = useRef();
  const observerRef = useRef();
  const handleScrollIntoView = () => {
    observerRef.current.classList.add('animate');
  };
  useScrollObserver(observerRef, handleScrollIntoView);

  const settings = {
    initialSlide: 0, // Ensure slider starts from the first slide
    infinite: true,
    speed: 300,
    swipe: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1124,
        settings: {
          variableWidth: true,
          slidesToShow: 1,
          swipe: true,
          arrows: false,
          dots: true,
          dotsClass: 'custom-dots',
          centerPadding: '15px',
        },
      },

      {
        breakpoint: 767,
        settings: {
          variableWidth: true,
          slidesToShow: 1,
          swipe: true,
          arrows: false,
          dots: true,
          dotsClass: 'custom-dots',
          centerPadding: '15px',
        },
      },
    ],
  };

  return (
    <>
      <style>
        {`
          .slick-list {
            overflow: hidden; 
          }
         .cases-slider .slick-track {
            display: flex;
            align-items: center;
          }
          .slick-slide {
            outline: none;
          }
          .slick-slide div {
            outline: none;
          }
          .custom-dots {
            display: flex !important;
            justify-content: center;
          }
          .custom-dots li {
            position: relative;
            margin: 0 3px;
            margin-top: 20px;
            width: 50px;
            height: 2px;
            background-color: #C4C4C4;
            overflow: hidden;
          }
         .cases-slider .custom-dots li.slick-active {
            background-color: #D9A8E5;
          }
          .custom-dots li button {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0;
          }
        `}
      </style>
      <div ref={observerRef} className="w-full ">
        <Slider {...settings} className="cases-slider w-full ">
          <div className="block px-[13px] pb-[48px] fade-in-from-bottom ease-out">
            <CasesCard
              cardClass="py-[32px] px-[23px] md:px-[28px]"
              logoUrl="/images/home/logos/hsbc.png"
              logoClass="max-h-[56px]"
              imageUrl="/images/home/hsbc.png"
              url="https://weprodigi.com/main/case/38"
              imageClass="bottom-[20px] md:top-[180px] fade-in-from-bottom animation-delay-400 ease-in"
              increaseNum="+180%"
              increaseText="customer engagement"
              avgNum="6000+"
              avgText="followers on social media"
              monthNum="1.5"
              monthText="years"
            />
          </div>
          <div className="block px-[13px] pb-[48px] fade-in-from-bottom ease-out animation-delay-200">
            <CasesCard
              cardClass="md:py-[32px] py-[28px] px-[16px] md:px-[22px]"
              logoUrl="/images/home/logos/bentley.png"
              logoClass="max-h-[90px]"
              imageUrl="/images/home/bentley.png"
              url="https://weprodigi.com/main/case/14"
              imageClass="bottom-[20px] md:top-[184px] fade-in-from-bottom  animation-delay-600 ease-in"
              increaseNum="70% "
              increaseText="impression share"
              avgNum="12%"
              avgText="click through rate"
              monthNum="2"
              monthText="months"
            />
          </div>
          <div className="block px-[13px] pb-[48px] fade-in-from-bottom ease-out animation-delay-400">
            <CasesCard
              cardClass="py-[30px] md:py-[32px] px-[16px] md:px-[32px]"
              logoUrl="/images/home/logos/gcaa.png"
              logoClass="max-h-[59px]"
              imageUrl="/images/home/gcaa.png"
              url="https://weprodigi.com/main/case/170"
              imageClass="bottom-[20px] md:top-[184px] fade-in-from-bottom animation-delay-800 ease-in"
              increaseNum="+95%"
              increaseText="increase in brand awareness"
              avgNum="65"
              avgText="new employees"
              monthNum="6"
              monthText="weeks "
            />
          </div>
        </Slider>
      </div>
    </>
  );
};

export default CasesSlider;

import classNames from 'classnames';
import React, { useState, useEffect, useRef } from 'react';

const ServicesItem = ({ title, description, isOpen, activeTab, onClick }) => {
  const [height, setHeight] = useState('0px');
  const contentRef = useRef(null);

  useEffect(() => {
    setHeight(isOpen ? `${contentRef.current.scrollHeight + 20}px` : '0px');
  }, [isOpen, activeTab]);

  return (
    <div
      className="flex items-center border-[1px] border-[#0052B4] rounded-[5px] bg-[#FFFFFF] cursor-pointer px-[29px] pt-[14px] pb-[18px]"
      onClick={onClick}
    >
      <div className="flex justify-between flex-col w-full text-left ">
        <div className="flex flex-row w-full justify-between">
          <h3 className="text-[16px] leading-[20px] font-[600] text-black">{title}</h3>
          <img src={`/images/home/icons/${isOpen ? 'minus-faq.svg' : 'plus-faq.svg'}`} alt="faq" />
        </div>
        <div
          ref={contentRef}
          style={{ maxHeight: height }}
          className={classNames(
            `max-w-[950px] text-[16px] font-[400] leading-[20px] overflow-hidden transition-height duration-300 ease-in-out text-black`,
            isOpen && 'mt-[16px]'
          )}
        >
          {description}
        </div>
      </div>
    </div>
  );
};

export default ServicesItem;

import React from 'react';
import AppearencesInMediaSlider from './AppearencesInMediaSlider';

const AppearencesInMedia = () => {
  return (
    <div className="bg-white">
      <div className="container py-[48px]">
        <div className="flex flex-col gap-[48px]">
          <h2 className="font-[600] md:text-center text-[32px] md:text-[34px] leading-[39px] md:leading-[44px] text-black">Appearences In Media</h2>
          <AppearencesInMediaSlider />
        </div>
      </div>
    </div>
  );
};

export default AppearencesInMedia;

import React from 'react';
import Cases from './Cases';
import Testimonials from './Testimonials';

const TrustedBy = () => {
  return (
    <div className="bg-[#F3F2F8] py-[48px]" id="our-expertise">
      <div className="container">
        <div className="flex flex-col md:gap-[48px] gap-[24px]">
          <div className="flex flex-col gap-[24px]">
            <h2 className="flex md:justify-center font-[600] text-[32px] md:text-[34px] leading-[39px] md:leading-[72px] text-black">
              Trusted by 120 Companies
            </h2>
            <p className={'block md:hidden  text-[#C7B6F3] text-[16px] font-semibold leading-[20px]'}>Testimonials</p>
          </div>

          <Testimonials />
          <Cases />
        </div>
      </div>
    </div>
  );
};

export default TrustedBy;

import React, { useRef } from 'react';
import ArrowLink from '../../../ui/ArrowLink';
import Slide from 'react-slick';
import 'slick-carousel/slick/slick.css';
import st from './index.module.css';

const Cases = () => {
  let toogleRef = useRef([]);

  const settings = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    swipe: true,
    autoplay: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <style>
        {`
          .home-cases .slick-list {
           height: 380px;
          }
          @media screen and (max-width: 400px) {
           .home-cases .slick-list {
              height: 340px !important;
            }
          }

    `}
      </style>
      <div className=" flex flex-col gap-[24px]">
        <p className={'block md:hidden  text-[#C7B6F3] text-[16px] font-semibold leading-[20px]'}>Cases</p>
        <Slide {...settings} className="home-cases !flex !flex-row !gap-[30px]">
          <div className="pr-[30px] md:h-auto h-[330px] md:w-[536px] w-[330px] md:px-0 md:mr-[28px]">
            <div className={`md:w-[536px] w-[330px] flex flex-col md:gap-[12px] gap-[24px]`}>
              <div className={`${st.video_item}`}>
                <video
                  src="https://weprodigi.com/uploads/images/home-page/PPCAdvertisingCaseStudy.mp4"
                  className="object-cover max-w-[330px] md:max-w-[540px]"
                  controls={true}
                  onChange={() => tooglePlayPause(0, toogleRef.current[0].paused)}
                  poster="https://admin.weprodigi.com/uploads/images/home-page/posterNare.jpg"
                  webkitplaysinline={'true'}
                  playsInline
                  width={'100%'}
                  ref={element => toogleRef.current.push(element)}
                ></video>
              </div>
              <div className="flex flex-col gap-[12px]">
                <h2 className="text-[#000000] leading-[20px] font-bolder text-[16px]">Arara Tour - PPC Case</h2>
                <p className="text-black font-normal leading-[20px] text-[16px]">
                  We helped Arara Tour achieve remarkable results, including a 1,745% increase in conversion volume, 42% decrease in cost per
                  conversion, and 480 leads.
                </p>
              </div>
            </div>
          </div>
          <div className="pr-[30px] md:h-auto h-[330px] md:px-0">
            <div className={`md:w-[536px] w-[330px] flex flex-col md:gap-[12px] gap-[24px]`}>
              <div className={`${st.video_item}`}>
                <video
                  src="https://weprodigi.com/uploads/images/home-page/VohcoLingerieSMMCase.mp4"
                  className="object-cover max-w-[330px] md:max-w-[540px]"
                  controls={true}
                  onChange={() => tooglePlayPause(0, toogleRef.current[0].paused)}
                  poster="https://admin.weprodigi.com/uploads/images/home-page/poster.jpeg"
                  webkitplaysinline={'true'}
                  playsInline
                  width={'100%'}
                  ref={element => toogleRef.current.push(element)}
                ></video>
              </div>
              <div className="flex flex-col gap-[12px]">
                <h2 className="text-[#000000] leading-[20px] font-bolder text-[16px]">Vohco - Ecommerce Case</h2>
                <p className="text-black font-normal leading-[20px] text-[16px]">
                  We have increased the sales from 0 to $9000 only in a month. The number of orders from 0 to 43.
                </p>
              </div>
            </div>
          </div>
        </Slide>
        <div className="flex justify-end w-full">
          <ArrowLink link="https://weprodigi.com/main/cases" text="More cases" />
        </div>
      </div>
    </>
  );
};

export default Cases;

import React from 'react';
import st from './index.module.css';
import classNames from 'classnames';

const TrustedByItem = props => {
  const { label, heading, headingClass, description, reviewer } = props;

  return (
    <div className={`${st.testimonial_item} md:w-auto w-[300px] md:h-auto h-[596px] md:px-[32px] px-[22px] py-[24px] rounded-[5px]`}>
      <div className="w-full h-full flex flex-col justify-between">
        <div className="flex flex-col  gap-[24px]">
          <div className="px-[16px] py-[8px] bg-[#C7B6F3] max-w-max rounded-[29px] text-[14px] font-normal leading-[17px]">{label}</div>
          <h2 className={classNames(headingClass, 'leading-[20px] font-semibold')}>{heading}</h2>
          <p className="text-[16px] font-normal leading-[20px]">{description}</p>
        </div>
        <div className={'flex justify-between md:flex-row flex-col gap-[24px] mt-[32px]'}>
          <div className="flex justify-start items-center ">
            <img src={reviewer.image} width={44} height={44} alt="review1" />
            <div className={'ml-[24px] flex flex-col gap-[8px]'}>
              <p className={'text-black text-[14px] font-[400] leading-[17px]'}>{reviewer.name}</p>
              <p className={'text-[12px] font-[400] leading-[15px] text-purple capitalize'}>{reviewer.position}</p>
            </div>
          </div>
          <a aria-label="view more" href={reviewer.reviewUrl} target={'_blank'}>
            <img src="https://weprodigi.com/uploads/images/home-page/clutch.svg" className="max-h-[45px]" alt="clutch" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default TrustedByItem;

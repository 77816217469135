import st from './index.module.css';
import ArrowLink from '../../../ui/ArrowLink';

const InsideProdigiMobileItem = ({ item }) => {
  return (
    <div className={st.card}>
      <div className="flex flex-col gap-[40px] items-center">
        <div className="flex flex-col justify-center gap-[32px] max-w-[570px] px-[24px] pt-[24px]">
          <h3 className="text-[24px] font-semibold leading-[29px]">{item.title}</h3>
          <p className="text-[16px] font-normal leading-[24px]">{item.description}</p>

          {item.linkText && <ArrowLink link={item.linkUrl} text={item.linkText} />}
        </div>

        <img src={item.image} alt={item.title} className="w-fit px-[8px]" />
      </div>
    </div>
  );
};

export default InsideProdigiMobileItem;

import axios from 'axios';

export async function getLatestArticles() {
  const response = await axios.get('https://blog-admin.weprodigi.com/api/get-latest');
  return response.data.latest_articles;
}

export async function getAllArticles(category = '', search = '', limit = 6, currentPage = 1) {
  const response = await axios.get(
    `https://blog-admin.weprodigi.com/api/get-all-posts?limit=${limit}&currentPage=${currentPage}&category=${category}&q=${search}`
  );
  return response.data;
}

export async function getCategories() {
  const response = await axios.get(`https://blog-admin.weprodigi.com/api/get-categories`);
  return response.data.categories;
}

export async function getBlogPost(post) {
  const response = await axios.get(process.env.NEXT_PUBLIC_API_URL + 'blog/' + post);
  return response.data;
}

export async function getPostsByKey(category) {
  const response = await axios.get(`https://blog-admin.weprodigi.com/api/filter-post-by-key?category=${category}`);

  return response.data;
}

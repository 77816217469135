import Head from 'next/head';

import {
  Hero,
  OurServices,
  AboutClients,
  AppearencesInMedia,
  Cases,
  BlogPosts,
  CertifiedBy,
  InsideProdigi,
  InsideProdigiMobile,
  Navigation,
  TrustedBy,
  MeetTeam,
} from '../components/home/sections';

import React, { useEffect } from 'react';

const HomePage = props => {
  useEffect(() => {
    document.body.classList.remove('overflow-x-hidden');
  });

  return (
    <div>
      <Head>
        <title>Result-Guaranteed Digital Marketing Services - WeProdigi</title>
        <meta
          name="description"
          content="We are a digital marketing and production company specializing in PPC services, SMM, Web Development, Games, and Video production services."
        />
        <meta httpEquiv="ScreenOrientation" content="autoRotate:disabled" />
        <meta property="og:url" content="https://weprodigi.com/" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Result-Guaranteed Digital Marketing Services - WeProdigi" />
        <meta
          property="og:description"
          content="We are a digital marketing and production company specializing in PPC services, SMM, Web Development, Games, and Video production services."
        />
        <meta property="og:image" content="https://weprodigi.com/images/home/og.png" />
        <script
          type="application/ld+json"
          className="organization-schema"
          dangerouslySetInnerHTML={{
            __html: `
                {
                
        "@context": "https://schema.org",
        "@type": "Organization",
        "@id": "https://weprodigi.com#Organization",
        "address": "Anastas Mikoyan 2/2, Yerevan 0090, Armenia",
        "award": [],
        "description": "Prodigi is a digital marketing and production company specializing in PPC services, SMM, Web Development, Games, and Video production services.",
        "alternateName": "Prodigi",
        "email": "info@weprodigi.com",
        "foundingDate": "2017-09-28",
        "image": "https://weprodigi.com/uploads/images/home-page/our_services.jpg",
        "knowsLanguage": [
        "hy",
        "en",
        "ru",
        "fr"
    ],
        "legalName": "Prodigi LLC",
        "logo": "https://weprodigi.com/images/logovertical.svg",
        "name": "Prodigi Marketing Agency",
        "sameAs": [
        "https://www.facebook.com/weprodigi/",
        "https://www.instagram.com/weprodigi_agency/",
        "https://www.linkedin.com/company/pro.digi/",
        "https://www.youtube.com/channel/UC6mkepDFKswxQWS76hgOMBg",
        "https://twitter.com/WeProdigi",
        "https://goo.gl/maps/rfbWAUxxzA2Kb4am8"
    ],
        "slogan": "Digital Marketing Agency That Brings Results",
        "telephone": [
        "+37412202040",
        "+37444755855",
        "+14378860987",
        "+442045770272",
        "+61288800313",
        "+19295909255"
    ],
        "url": "https://weprodigi.com/",
        "knowsAbout": [
        "https://en.wikipedia.org/wiki/Web_design",
        "https://en.wikipedia.org/wiki/Digital_marketing",
        "https://en.wikipedia.org/wiki/Graphic_design",
        "https://en.wikipedia.org/wiki/Video_clip#Advertising",
        "https://en.wikipedia.org/wiki/Social_media_marketing",
        "https://en.wikipedia.org/wiki/Search_engine_optimization",
        "https://en.wikipedia.org/wiki/Online_advertising"
    ],
        "taxID": "01278077",
        "founder": [
        {
            "@id": "https://weprodigi.com#BorisSahakyan"
        },
        {
            "@id": "https://weprodigi.com#AidaVopyan"
        },
        {
            "@id": "https://weprodigi.com#ArsenSultanyan"
        }
    ],
        "numberOfEmployees": {
        "@id": "https://weprodigi.com#NumberOfEmployees"
    },
        "aggregateRating": {
        "@id": "https://weprodigi.com#AggRating"
    }
    }
        `,
          }}
        />

        <script
          type="application/ld+json"
          className="organization-schema"
          dangerouslySetInnerHTML={{
            __html: `
               {"@context": "https://schema.org",
        "@type": "Person",
        "@id": "https://weprodigi.com#BorisSahakyan",
        "colleague": "https://en.wikipedia.org/wiki/Yerevan_State_University",
        "gender": "male",
        "image": "https://weprodigi.com/images/team/boris2.png",
        "jobTitle": "Co-founder",
        "knowsLanguage": [
        "hy",
        "en",
        "ru"
    ],
        "name": "Boris Sahakyan",
        "sameAs": [
        "https://www.linkedin.com/in/boris-sahakyan-3243364b/",
        "https://www.facebook.com/BorisSahakyan"
    ]
    }
        `,
          }}
        />
        <script
          type="application/ld+json"
          className="organization-schema"
          dangerouslySetInnerHTML={{
            __html: `
               {
        "@context": "https://schema.org",
        "@type": "Person",
        "@id": "https://weprodigi.com#ArsenSultanyan",
        "gender": "male",
        "image": "https://weprodigi.com/images/team/arsen2.png",
        "birthDate": "1981-12-05",
        "colleague": "https://en.wikipedia.org/wiki/Armenian_State_University_of_Economics",
        "jobTitle": "Co-founder",
        "knowsLanguage": [
        "hy",
        "en",
        "ru"
    ],
        "name": "Arsen Sultanyan",
        "sameAs": [
        "https://www.facebook.com/arsen.sultanyan",
        "https://www.linkedin.com/in/arsensultanyan/"
    ]
    }
        `,
          }}
        />

        <script
          type="application/ld+json"
          className="organization-schema"
          dangerouslySetInnerHTML={{
            __html: `
               {
        "@context": "https://schema.org",
        "@type": "AggregateRating",
        "@id": "https://weprodigi.com#AggRating",
        "bestRating": "5",
        "ratingCount": "23",
        "ratingValue": "5",
        "url": "https://goo.gl/maps/rfbWAUxxzA2Kb4am8",
        "worstRating": "1",
        "name": "Google Map AggRating"
    }
        `,
          }}
        />
      </Head>
      <Hero country={props.country} currency={props.currency} />
      <div className="relative md:bg-[#F3F2F8]">
        <div className="md:block hidden" style={{ position: 'sticky', top: '0px', zIndex: '888' }}>
          <Navigation />
        </div>
        <OurServices country={props.country} currency={props.currency} />
        <CertifiedBy />
        <TrustedBy />
        <AboutClients />
        <Cases />
        <div className="md:block hidden">
          <InsideProdigi />
        </div>
        <div className="md:hidden block">
          <InsideProdigiMobile />
        </div>
        <MeetTeam country={props.country} currency={props.currency} />
      </div>
      <AppearencesInMedia />
      <BlogPosts />
    </div>
  );
};

export default HomePage;
